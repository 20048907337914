import React from "react";
import { Container, Image } from "react-bootstrap";

const ButtonLoginOptionsBlock = ({ data }) => {
  return (
    <Container>
      <div className="PolicyFormBlock__content__wrapper">
        <div className="ButtonBlock__title">{data.title}</div>
        <div className="ButtonBlock__button__wrapper">
          {data.buttonOptions.map((buttonOption) => (
            <div className="ButtonBlock__group__wrapper">
              <div className="ButtonBlock__button">
                <a href={buttonOption.buttonURL}>{buttonOption.buttonLabel}</a>
              </div>
              {buttonOption?.displayImage && (
                <div>
                  {buttonOption?.imageDescription}
                  <a href={buttonOption?.buttonURL}>
                    <Image
                      className="ButtonBlock__image"
                      fluid
                      src={buttonOption?.buttonIMGURL}
                    />
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="ButtonBlock__condition__wrapper">
          {data.condition.map((condition) => (
            <div className="ButtonBlock__condition">{condition.list}</div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default ButtonLoginOptionsBlock;
