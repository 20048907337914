import React from "react";
import clsx from "clsx";
import parse from "html-react-parser";

const Fieldset = ({ children, className, legend }) => (
  <fieldset className={clsx("Fieldset", className)}>
    {legend && (
      <legend className="Fieldset__legend">
        <span>{parse(legend)}</span>
      </legend>
    )}
    {children}
  </fieldset>
);

export default Fieldset;
