import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Fieldset from "../../../../../../../../Fieldset/Fieldset";
import Input from "../../../../../../../../Input/Input";
import Select from "../../../../../../../../Select/Select";
import usStates from "../../../us_states.json";
import useScreen from "../hooks/useScreen";

const Screen = ({ form, screenIndex, wizard }) => {
  const policyNumberValue = form?.watch("policyNumber");
  const dateOfAccidentValue = form?.watch("dateOfAccident");
  const timeOfAccidentValue = form?.watch("timeOfAccident");
  const locationOfAccidentStateValue = form?.watch("locationOfAccidentState");
  const dateValidation = form?.formState.errors.dateOfAccident;
  const zipValidation = form?.formState.errors.locationOfAccidentZip;

  const isInvalid = useMemo(() => {
    if (
      !policyNumberValue ||
      !dateOfAccidentValue ||
      !timeOfAccidentValue ||
      !locationOfAccidentStateValue
    )
      return true;
    if (dateValidation || zipValidation) return true;
    return false;
  }, [
    policyNumberValue,
    dateOfAccidentValue,
    timeOfAccidentValue,
    locationOfAccidentStateValue,
    dateValidation,
    zipValidation,
  ]);

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <>
      <Row>
        <Col xs={12} sm={8}>
          <Input
            autoComplete="off"
            error={form?.formState.errors.policyNumber}
            id="policyNumber"
            label="Stonegate Insurance Policy Number"
            touched={form?.formState.touchedFields.policyNumber}
            {...form?.register("policyNumber", {
              onBlur: () => form?.trigger("policyNumber"),
              required: "This field is required.",
            })}
          />
        </Col>
      </Row>
      <span aria-hidden="true" />
      <Row>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            error={form?.formState.errors.dateOfAccident}
            id="dateOfAccident"
            label="Date of accident"
            type="date"
            min="2000-01-01"
            max={new Date().toISOString().split("T")[0]}
            touched={form?.formState.touchedFields.dateOfAccident}
            {...form?.register("dateOfAccident", {
              onBlur: () => form?.trigger("dateOfAccident"),
              required: "This field is required.",
            })}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            error={form?.formState.errors.timeOfAccident}
            id="timeOfAccident"
            label="Time of accident"
            type="time"
            touched={form?.formState.touchedFields.timeOfAccident}
            {...form?.register("timeOfAccident", {
              onBlur: () => form?.trigger("timeOfAccident"),
              required: "This field is required.",
            })}
          />
        </Col>
      </Row>
      <span aria-hidden="true" />
      <Fieldset legend="Location of Loss">
        <Input
          autoComplete="off"
          error={form?.formState.errors.locationOfAccidentStreetAddress}
          id="locationOfAccidentStreetAddress"
          label="Street address"
          {...form?.register("locationOfAccidentStreetAddress")}
        />
        <Row>
          <Col xs={12} sm={6} md={4}>
            <Input
              autoComplete="off"
              error={form?.formState.errors.locationOfAccidentCity}
              id="locationOfAccidentCity"
              label="City"
              {...form?.register("locationOfAccidentCity")}
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Select
              autoComplete="off"
              error={form?.formState.errors.locationOfAccidentState}
              id="locationOfAccidentState"
              label="State"
              touched={form?.formState.touchedFields.locationOfAccidentState}
              {...form?.register("locationOfAccidentState", {
                onBlur: () => form?.trigger("locationOfAccidentState"),
                required: "This field is required.",
              })}
            >
              <option disabled value="" />
              {usStates.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Select>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Input
              autoComplete="off"
              error={form?.formState.errors.locationOfAccidentZip}
              touched={form?.formState.touchedFields.locationOfAccidentZip}
              id="locationOfAccidentZip"
              label="Zip"
              {...form?.register("locationOfAccidentZip", {
                onChange: () => form?.trigger("locationOfAccidentZip"),
              })}
            />
          </Col>
        </Row>
      </Fieldset>
    </>
  );
};

export default Screen;
