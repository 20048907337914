import React, { useMemo } from "react";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";
import { Container, Row, Col } from "react-bootstrap";
import Helpers from "../../../../../utils/Helpers";
import Header from "../../../../Header/Header";
import useIsBlockVisible from "../../../hooks/useIsBlockVisible";
import Button from "./components/Button";

const HeaderParagraphBlock = ({ as, className, data, headerAs, ...rest }) => {
  const Tag = useMemo(() => as || "section", [as]);
  const isVisible = useIsBlockVisible(data, ["header", "body", "buttonText"]);

  if (!isVisible) return null;

  return (
    <Tag className={clsx("HeaderParagraphBlock__wrapper", className)} {...rest}>
      <Container fluid>
        <div className="HeaderParagraphBlock">
          {data.header && (
            <Header
              as={headerAs}
              className="HeaderParagraphBlock__header"
              header={data.header}
            />
          )}
          {data.body && (
            <Markdown
              className="HeaderParagraphBlock__body"
              options={{ forceBlock: true }}
            >
              {Helpers.preventOrphans(data.body)}
            </Markdown>
          )}
          <Row>
            {data.buttonText &&
              (data.page || data.buttonLink || data.onClick) &&
              (() => {
                const { buttonText, buttonLink, disabled, onClick, page } =
                  data;

                return (
                  <Col>
                    <Button
                      button={{
                        buttonLink,
                        buttonText,
                        disabled,
                        onClick,
                        page,
                      }}
                      className="HeaderParagraphBlock__button"
                    />
                  </Col>
                );
              })()}
            {data.loginButton && (
              <Col xs={12} className="mt-5">
                <a
                  className="Button Button__Grey"
                  href={data.loginLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.loginText}
                </a>
              </Col>
            )}
          </Row>
        </div>
      </Container>
    </Tag>
  );
};

export default HeaderParagraphBlock;
