import React, { useMemo } from "react";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";
import { Container } from "react-bootstrap";

const TextColumnsBlock = ({ className, data, ...rest }) => {
  const isVisible = useMemo(() => data.columns.length > 0, [data]);

  if (!isVisible) return null;

  return (
    <section className={clsx("TextColumnsBlock", className)} {...rest}>
      <Container>
        <div className="TextColumnsBlock__columns">
          {data.columns.map(({ id, text }) => (
            <div className="TextColumnsBlock__column" key={id}>
              <Markdown
                className="TextColumnsBlock__column__text"
                options={{ forceBlock: true }}
              >
                {text}
              </Markdown>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default TextColumnsBlock;
