import React from "react";
import clsx from "clsx";
import parse from "html-react-parser";
import { Container } from "react-bootstrap";
import BackgroundImage from "../../../../BackgroundImage/BackgroundImage";
import useHeaderAs from "../../../hooks/useHeaderAs";

const ImageTitleBlock = ({ data, className, ...rest }) => {
  const headerTextColor = "white";
  const [ref, headerAs] = useHeaderAs("h1", "h2");

  return (
    <section
      className={clsx("ImageTitleBlock__wrapper", className)}
      ref={ref}
      {...rest}
    >
      <Container>
        <div className="ImageTitleBlock">
          <div
            className={clsx(
              "ImageTitleBlock__header__wrapper",
              headerTextColor,
            )}
          >
            {headerAs &&
              (() => {
                const Header = headerAs;
                return (
                  <Header className="ImageTitleBlock__header">
                    {parse(data.header)}
                  </Header>
                );
              })()}
          </div>
          <BackgroundImage
            className="ImageTitleBlock__backgroundImage"
            image={data.image}
          />
        </div>
      </Container>
    </section>
  );
};

export default ImageTitleBlock;
