import React, { useMemo } from "react";
import clsx from "clsx";
import { Container } from "react-bootstrap";
import { get } from "lodash";
import { Link } from "react-router-dom";
import CompanyInfo from "../CompanyInfo/CompanyInfo";
import DividerBlock from "../PageBlocks/components/ContentBlocks/DividerBlock/DividerBlock";
import CookiePolicy from "../CookiePolicy/CookiePolicy";

const Footer = ({ className, companyInfo, footer }) => {
  const { badges, logoIcon } = footer;
  const year = useMemo(() => new Date().getFullYear(), []);
  const pageLinks = useMemo(() => {
    const data = get(footer, "page_links.data");
    return data || [];
  }, [footer]);

  return (
    <footer className={clsx("Footer__wrapper", className)}>
      <DividerBlock />
      <div className="Footer">
        <Container>
          {logoIcon && (
            <Link className="Footer__logo__wrapper" to="/">
              {logoIcon.srcLink ? (
                <img src={logoIcon.srcLink} alt="logo" height={"50px"}></img>
              ) : (
                <img
                  src={logoIcon?.image?.data?.attributes?.url}
                  alt={
                    logoIcon?.image?.data?.attributes?.alternativeText ?? "logo"
                  }
                  height={"50px"}
                  className="Footer__logo"
                ></img>
              )}
            </Link>
          )}
          <div className="Footer__columns">
            {badges &&
              badges.map((badge, index) => (
                <a
                  key={index}
                  href={badge?.linkUrl ?? badge.linkPdf?.data?.attributes?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {badge.srcLink ? (
                    <img
                      src={badge.srcLink}
                      alt={"badge" + index}
                      height={badge.imgHeight}
                    />
                  ) : (
                    <img
                      src={badge?.image?.data?.attributes?.url}
                      alt={
                        logoIcon?.image?.data?.attributes?.alternativeText ??
                        "badge" + index
                      }
                      height={badge.imgHeight ?? "50px"}
                      className="Footer__accreditation"
                    />
                  )}
                </a>
              ))}
          </div>
          <CompanyInfo companyInfo={companyInfo} />
          <div className="Footer__pageLinks">
            {pageLinks.map((link) => (
              <Link
                className="Footer__pageLink"
                key={link.id}
                to={`/${link.attributes.slug}`}
              >
                {link.attributes.pageName}
              </Link>
            ))}
            <span>Copyright {year}</span>
          </div>
        </Container>
      </div>
      <div>
        <CookiePolicy />
      </div>
    </footer>
  );
};

export default Footer;
