import React, { useMemo } from "react";
import clsx from "clsx";
import { Container } from "react-bootstrap";
import HeaderParagraphBlock from "../../ContentBlocks/HeaderParagraphBlock/HeaderParagraphBlock";
import useHeaderAs from "../../../hooks/useHeaderAs";

const InfographicBlock = ({ className, data, ...rest }) => {
  const [ref, headerAs] = useHeaderAs("h1", "h2");
  const isVisible = useMemo(() => data.infographics.length > 0, [data]);

  if (!isVisible) return null;

  return (
    <section
      className={clsx("InfographicBlock__wrapper", className)}
      ref={ref}
      {...rest}
    >
      <Container>
        <div className="InfographicBlock">
          <HeaderParagraphBlock
            as="div"
            data={{
              header: data.header,
            }}
            headerAs={headerAs}
          />
          <div className="InfographicBlock__column__wrapper">
            {data?.infographics.map(({ body, id, image }, i) => (
              <div
                className={"InfographicBlock__column"}
                key={id}
                style={{ width: 100 / data?.infographics.length + "%" }}
              >
                <img
                  src={image.data?.attributes?.formats?.thumbnail?.url}
                  alt="img"
                />
                <div className="InfographicBlock__number__wrapper">
                  <div className="InfographicBlock__number">
                    <div className="dashed"></div>
                    <p>{i + 1}</p>
                    <div className="dashed"></div>
                  </div>
                </div>
                <p>{body}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default InfographicBlock;
