import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AOS from "aos";
import { get, set } from "lodash";
import { useSpring } from "react-spring";

const useSliderBlock = (data) => {
  const ref = useRef(null);

  // filter out slides without content
  const slides = useMemo(() => {
    const key = ["body", "header", "image.data", "name", "title"];
    return data.slides.filter((slide) => key.some((key) => get(slide, key)));
  }, [data.slides]);

  // if there is only one slide, don't show
  // the pagination and disable dragging
  const isPaginationVisible = useMemo(() => slides.length > 1, [slides]);

  const [ariaLabel, setAriaLabel] = useState("");
  const updateAriaLabel = useCallback(
    ({ index }) => {
      const i = index || 0;
      const newAriaLabel = get(slides, `[${i}].header`);

      // in the slight chance that there is no `header`
      // coming from strapi, simply unset the aria-label
      setAriaLabel(newAriaLabel || "");
    },
    [slides, setAriaLabel],
  );

  const [currentSlideHasNoImage, setCurrentSlideHasNoImage] = useState(true);
  const updateCurrentSlideHasNoImage = useCallback(
    ({ index }) => {
      const activeSlideData = slides[index];
      const hasImage = Boolean(get(activeSlideData, "image.data"));
      setCurrentSlideHasNoImage(!hasImage);
    },
    [slides, setCurrentSlideHasNoImage],
  );

  const [height, setHeight] = useState(0);
  const { height: animatedHeight } = useSpring({ height, from: { height: 0 } });
  const updateHeight = useCallback(
    ({ Components, index }) => {
      const activeSlide = Components.Slides.getAt(index);
      const offsetHeight = get(activeSlide, "slide.offsetHeight");

      // in the slight chance that an `activeSlide` isn't
      // found, we'll need to disabled the animation to
      // prevent a display issue where the content is
      // hidden in a <div> with a height set to `0`
      const newHeight = offsetHeight || null;
      setHeight(newHeight);
    },
    [setHeight],
  );

  // refresh AOS whenever `height` changes
  const refreshTimeoutRef = useRef(null);
  useEffect(() => {
    const refreshTimeoutRefCopy = refreshTimeoutRef.current;

    set(
      refreshTimeoutRef,
      "current",
      setTimeout(() => {
        set(refreshTimeoutRef, "current", null);
        AOS.refresh();
      }, 100),
    );

    return () => clearTimeout(refreshTimeoutRefCopy);
  }, [height]);

  const handleMove = useCallback(
    (...args) => {
      updateAriaLabel(...args);
      updateCurrentSlideHasNoImage(...args);
      updateHeight(...args);

      // scroll to top of section
      // ref.current?.scrollIntoView({
      //   behavior: 'smooth',
      //   inline: 'start',
      //   block: 'start',
      // });
    },
    [updateAriaLabel, updateCurrentSlideHasNoImage, updateHeight],
  );

  // using `setTimeout` for "next tick"
  const readyTimeoutRef = useRef(null);
  const handleReady = useCallback(
    (...args) =>
      set(
        readyTimeoutRef,
        "current",
        setTimeout(() => {
          set(readyTimeoutRef, "current", null);

          updateAriaLabel(...args);
          updateCurrentSlideHasNoImage(...args);
          updateHeight(...args);
        }, 0),
      ),
    [updateAriaLabel, updateCurrentSlideHasNoImage, updateHeight],
  );
  useEffect(() => () => clearTimeout(readyTimeoutRef.current), []);

  return {
    animatedHeight,
    ariaLabel,
    currentSlideHasNoImage,
    handleMove,
    handleReady,
    isPaginationVisible,
    slides,
    ref,
    updateHeight,
  };
};

export default useSliderBlock;
