import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Logo from "../MainMenus/components/Logo/Logo";

const LoginHeader = ({ className }) => {
  const [areMainLinksVisible, setMainLinksVisibility] = useState(false);
  const location = useLocation();

  useEffect(() => setMainLinksVisibility(false), [location]);

  return (
    <nav className={clsx("MainMenusLogin", className, { areMainLinksVisible })}>
      <Container fluid="lg" className="MainMenu__Items">
        <Logo
          areMainLinksVisible={areMainLinksVisible}
          loginHeader={true}
          className="MainMenus__logo"
          url={
            "https://strapi.producersnational.com/uploads/producers_blue_logo_0fc4a97412.svg"
          }
          redirect={"https://producersnational.com/"}
        />
        <div className="MainMenusLogin__topLinks">
          <Logo
            areMainLinksVisible={areMainLinksVisible}
            loginHeader={true}
            url={
              "https://strapi.producersnational.com/uploads/logo_white_0ee95c251d.svg"
            }
            className="MainMenus__logo"
            redirect={"https://www.stonegateins.com/"}
          />
          <Logo
            areMainLinksVisible={areMainLinksVisible}
            loginHeader={true}
            url={
              "https://strapi.producersnational.com/uploads/unique_white_logo_35c9f4335e.svg"
            }
            className="MainMenus__logo"
            redirect={"https://uniqueinsuranceco.com/"}
          />
        </div>
      </Container>
    </nav>
  );
};

export default LoginHeader;
