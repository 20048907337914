import React from "react";
import clsx from "clsx";
import ErrorMessage from "./components/ErrorMessage/ErrorMessage";

const InputWrapper = ({
  children,
  className,
  error,
  helperText,
  isLabelHidden,
  label,
  labelHtmlFor,
  labelId,
  touched,
}) => (
  <div className={clsx("InputWrapper", className)}>
    {label && (
      <label
        className={clsx("InputWrapper__label", {
          "visually-hidden": isLabelHidden,
        })}
        htmlFor={labelHtmlFor}
        id={labelId}
      >
        {label}
      </label>
    )}
    <div className="InputWrapper__input">{children}</div>
    {touched && error && <ErrorMessage error={error} />}
    {helperText && <p className="InputWrapper__helperText">{helperText}</p>}
  </div>
);

export default InputWrapper;
