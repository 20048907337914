import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Oval } from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";
import { ReactComponent as SVGlogo } from "../../assets/logo.svg";

const LoadingIndicator = (hasLogo) => {
  const { promiseInProgress } = usePromiseTracker();

  if (!promiseInProgress) return null;

  return (
    <div className="LoadingIndicator">
      {hasLogo && <SVGlogo className="LoadingIndicator__logo" />}
      <Oval
        ariaLabel="loading"
        color="#B0B0B0"
        secondaryColor="#B0B0B0"
        strokeWidth={2}
        strokeWidthSecondary={2}
        visible={true}
        width={"90%"}
        width={"90%"}
      />
    </div>
  );
};

export default LoadingIndicator;
