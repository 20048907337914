import React, { useMemo } from "react";
import clsx from "clsx";
import { Container } from "react-bootstrap";
import useIsBlockVisible from "../../../hooks/useIsBlockVisible";
import Button from "./components/Button";

const HeaderButtonBlock = ({ as, className, data, headerAs, ...rest }) => {
  const Tag = useMemo(() => as || "section", [as]);
  const isVisible = useIsBlockVisible(data, ["header", "body", "buttonText"]);

  if (!isVisible) return null;

  return (
    <Tag className={clsx("HeaderButtonBlock__wrapper", className)} {...rest}>
      <Container fluid>
        <div className="HeaderButtonBlock">
          {data.subHeader && (
            <h2 className="HeaderButtonBlock__sub__header ">
              {data.subHeader}
            </h2>
          )}
          {data.header && (
            <h2 className="HeaderButtonBlock__header">{data.header}</h2>
          )}
          {data.buttonText &&
            (data.page || data.buttonLink || data.onClick) &&
            (() => {
              const { buttonText, buttonLink, disabled, onClick, page } = data;

              return (
                <Button
                  button={{
                    buttonLink,
                    buttonText,
                    disabled,
                    onClick,
                    page,
                  }}
                  className="HeaderButtonBlock__button"
                />
              );
            })()}
        </div>
      </Container>
    </Tag>
  );
};

export default HeaderButtonBlock;
