import React from "react";
import clsx from "clsx";
import parse from "html-react-parser";
import BackgroundImage from "../../../BackgroundImage/BackgroundImage";
import useHeaderAs from "../../hooks/useHeaderAs";

const GlobalHeroBlock = ({ data, className, ...rest }) => {
  const headerTextColor = "white";
  const [ref, headerAs] = useHeaderAs("h1", "h2");

  return (
    <section className={clsx("GlobalHeroBlock", className)}>
      <div
        className={clsx("GlobalHeroBlock__header__wrapper", headerTextColor)}
        ref={ref}
        {...rest}
      >
        {headerAs &&
          (() => {
            const Header = headerAs;
            return (
              <Header className="GlobalHeroBlock__header">
                {parse(data.header)}
              </Header>
            );
          })()}
      </div>
      <BackgroundImage
        className="GlobalHeroBlock__backgroundImage"
        image={data.image}
      />
    </section>
  );
};

export default GlobalHeroBlock;
