import React from "react";
import clsx from "clsx";
import parse from "html-react-parser";
import { Container } from "react-bootstrap";
import useHeaderAs from "../../../hooks/useHeaderAs";
import useIsBlockVisible from "../../../hooks/useIsBlockVisible";
import Form from "./components/Form";

const ContactFormBlock = ({ data, className, ...rest }) => {
  // TODO: wire up strapi to return options for `white`, `black`
  const [ref, headerAs] = useHeaderAs("h1", "h2");
  const isVisible = useIsBlockVisible(data, ["header", "displayForm"]);

  if (!isVisible) return;

  return (
    <section
      className={clsx("ContactFormBlock__wraper", className)}
      ref={ref}
      {...rest}
    >
      <Container>
        <div className="ContactFormBlock">
          {data.header && (
            <div className="ContactFormBlock__header__wrapper">
              {headerAs &&
                (() => {
                  const Header = headerAs;
                  return (
                    <Header className="ContactFormBlock__header">
                      {parse(data.header)}
                    </Header>
                  );
                })()}
            </div>
          )}
          {data.displayForm && <Form className="ContactFormBlock__form" />}
        </div>
      </Container>
    </section>
  );
};

export default ContactFormBlock;
