import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Fieldset from "../../../../../../../../Fieldset/Fieldset";
import Input from "../../../../../../../../Input/Input";
import Select from "../../../../../../../../Select/Select";
import usStates from "../../../us_states.json";
import useScreen from "../hooks/useScreen";

const Screen = ({ form, screenIndex, wizard }) => {
  const emailValidation = form?.formState.errors.otherVehicleOwnerEmail;
  const workPhoneValidation = form?.formState.errors.otherVehicleOwnerWorkPhone;
  const homePhoneValidation = form?.formState.errors.otherVehicleOwnerHomePhone;
  const cellPhoneValidation = form?.formState.errors.otherVehicleOwnerCellPhone;
  const zipValidation = form?.formState.errors.otherVehicleOwnerZip;

  const isInvalid = useMemo(() => {
    if (
      emailValidation ||
      workPhoneValidation ||
      homePhoneValidation ||
      cellPhoneValidation
    )
      return true;
    if (zipValidation) return true;
    return false;
  }, [
    emailValidation,
    workPhoneValidation,
    homePhoneValidation,
    cellPhoneValidation,
    zipValidation,
  ]);

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <Fieldset legend="Other Vehicle Owner Information">
      <Row>
        <Col xs={12} sm={6} md={4}>
          <Input
            autoComplete="off"
            error={form?.formState.errors.otherVehicleOwnerFirstName}
            id="otherVehicleOwnerFirstName"
            label="First name"
            touched={form?.formState.touchedFields.otherVehicleOwnerFirstName}
            {...form?.register("otherVehicleOwnerFirstName", {
              onBlur: () => form?.trigger("otherVehicleOwnerFirstName"),
            })}
          />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Input
            autoComplete="off"
            id="otherVehicleOwnerMiddleName"
            label="Middle name"
            {...form?.register("otherVehicleOwnerMiddleName")}
          />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Input
            autoComplete="off"
            error={form?.formState.errors.otherVehicleOwnerLastName}
            id="otherVehicleOwnerLastName"
            label="Last name"
            touched={form?.formState.touchedFields.otherVehicleOwnerLastName}
            {...form?.register("otherVehicleOwnerLastName", {
              onBlur: () => form?.trigger("otherVehicleOwnerLastName"),
            })}
          />
        </Col>
      </Row>
      <Input
        autoComplete="off"
        id="otherVehicleOwnerStreetAddress"
        label="Street address"
        {...form?.register("otherVehicleOwnerStreetAddress")}
      />
      <Row>
        <Col xs={12} sm={4}>
          <Input
            autoComplete="off"
            id="otherVehicleOwnerCity"
            label="City"
            {...form?.register("otherVehicleOwnerCity")}
          />
        </Col>
        <Col xs={12} sm={4}>
          <Select
            autoComplete="off"
            id="otherVehicleOwnerState"
            label="State"
            {...form?.register("otherVehicleOwnerState")}
          >
            <option disabled value="" />
            {usStates.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Select>
        </Col>
        <Col xs={12} sm={4}>
          <Input
            autoComplete="off"
            id="otherVehicleOwnerZip"
            label="Zip Code"
            maxLength={10}
            touched={form?.formState.touchedFields.otherVehicleOwnerZip}
            error={form?.formState.errors.otherVehicleOwnerZip}
            {...form?.register("otherVehicleOwnerZip", {
              onChange: () => form?.trigger("otherVehicleOwnerZip"),
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <Input
            id="otherVehicleOwnerHomePhone"
            label="Home phone number"
            type="tel"
            touched={form?.formState.touchedFields.otherVehicleOwnerHomePhone}
            error={form?.formState.errors.otherVehicleOwnerHomePhone}
            {...form?.register("otherVehicleOwnerHomePhone", {
              onChange: () => form?.trigger("otherVehicleOwnerHomePhone"),
            })}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            id="otherVehicleOwnerCellPhone"
            label="Cell phone number"
            type="tel"
            touched={form?.formState.touchedFields.otherVehicleOwnerCellPhone}
            error={form?.formState.errors.otherVehicleOwnerCellPhone}
            {...form?.register("otherVehicleOwnerCellPhone", {
              onChange: () => form?.trigger("otherVehicleOwnerCellPhone"),
            })}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            id="otherVehicleOwnerWorkPhone"
            label="Work phone number"
            type="tel"
            touched={form?.formState.touchedFields.otherVehicleOwnerWorkPhone}
            error={form?.formState.errors.otherVehicleOwnerWorkPhone}
            {...form?.register("otherVehicleOwnerWorkPhone", {
              onChange: () => form?.trigger("otherVehicleOwnerWorkPhone"),
            })}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            error={form?.formState.errors.otherVehicleOwnerEmail}
            id="otherVehicleOwnerEmail"
            label="Email"
            touched={form?.formState.touchedFields.otherVehicleOwnerEmail}
            type="email"
            {...form?.register("otherVehicleOwnerEmail", {
              onBlur: () => form?.trigger("otherVehicleOwnerEmail"),
            })}
          />
        </Col>
      </Row>
    </Fieldset>
  );
};

export default Screen;
