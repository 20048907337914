import * as yup from "yup";

const max = 1000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  damageType: yup.string().default("").required("This field is required."),
  isVehicleDamaged: yup
    .string()
    .default("")
    .required("This field is required."),
  isVehicleDrivable: yup.string().default(""),
  isBusinessAddress: yup.string().default(""),
  pointOfImpact: yup.string().default("").required("This field is required."),
  vehicleDamageDescription: yup
    .string()
    .default("")
    .max(max, message)
    .when("isVehicleDamaged", {
      is: "Yes",
      then: yup.string().required("This field is required."),
      otherwise: yup.string().default(""),
    }),
  unrelatedPriorDamage: yup.string().default("").max(max, message),
  vehicleLocationStreetAddress: yup.string().default(""),
  vehicleLocationCity: yup.string().default(""),
  vehicleLocationState: yup.string().default(""),
  vehicleLocationZip: yup
    .string()
    .default("")
    .matches(
      /^(?:\d{5}(?:[-\s]?\d{4})?|\s*)$/,
      "Please enter a valid zip code.",
    ),
  businessName: yup.string().default(""),
  businessPhone: yup.string().default(""),
};

export default schema;
export { max };
