import { useEffect, useRef, useState } from "react";

const useHeaderAs = (headingA, headingB) => {
  const ref = useRef(null);
  const [headerAs, setHeaderAs] = useState(null);

  useEffect(() => {
    const index = parseInt(ref.current.dataset.index);
    const as = !index ? headingA : headingB;
    setHeaderAs(as);
  }, [headingA, headingB]);

  return [ref, headerAs];
};

export default useHeaderAs;
