import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Fieldset from "../../../../../../../../Fieldset/Fieldset";
import Input from "../../../../../../../../Input/Input";
import Select from "../../../../../../../../Select/Select";
import usStates from "../../../us_states.json";
import useScreen from "../hooks/useScreen";

import RadioGroup from "../../../../../../../../RadioGroup/RadioGroup";
import { max as maxLength } from "./schema";
import Textarea from "../../../../../../../../Textarea/Textarea";

const Screen = ({ form, screenIndex, wizard }) => {
  const pedestriansInvolved = form?.watch("pedestriansInvolved");
  const pedestriansInjured = form?.watch("pedestriansInjured");
  const emailValidation = form?.formState.errors.pedestriansEmail;
  const homePhoneValidation = form?.formState.errors.pedestriansHomePhone;
  const cellPhoneValidation = form?.formState.errors.pedestriansCellPhone;
  const workPhoneValidation = form?.formState.errors.pedestriansWorkPhone;
  const dateOfBirthValidation = form?.formState.errors.pedestriansDateOfBirth;
  const zipValidation = form?.formState.errors.pedestriansZip;

  const isInvalid = useMemo(() => {
    if (!pedestriansInvolved) return true;
    if (pedestriansInvolved === "Yes") {
      if (
        emailValidation ||
        homePhoneValidation ||
        cellPhoneValidation ||
        workPhoneValidation ||
        dateOfBirthValidation ||
        zipValidation
      )
        return true;
    }
    return false;
  }, [
    pedestriansInvolved,
    emailValidation,
    homePhoneValidation,
    cellPhoneValidation,
    workPhoneValidation,
    dateOfBirthValidation,
    zipValidation,
  ]);

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <>
      <Fieldset legend="Pedestrian Information">
        <Row>
          <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Was a pedestrian involved in this accident?"
              name="pedestriansInvolved"
              required
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          {pedestriansInvolved === "Yes" && (
            <>
              <Row>
                <Col xs={12} sm={6} md={4}>
                  <Input
                    autoComplete="off"
                    error={form?.formState.errors.pedestriansFirstName}
                    id="pedestriansFirstName"
                    label="First name"
                    touched={form?.formState.touchedFields.pedestriansFirstName}
                    {...form?.register("pedestriansFirstName", {
                      onBlur: () => form?.trigger("pedestriansFirstName"),
                    })}
                  />
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Input
                    autoComplete="off"
                    id="pedestriansMiddleName"
                    label="Middle name"
                    {...form?.register("pedestriansMiddleName")}
                  />
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Input
                    autoComplete="off"
                    error={form?.formState.errors.pedestriansLastName}
                    id="pedestriansLastName"
                    label="Last name"
                    touched={form?.formState.touchedFields.pedestriansLastName}
                    {...form?.register("pedestriansLastName", {
                      onBlur: () => form?.trigger("pedestriansLastName"),
                    })}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12}>
                  <Input
                    autoComplete="off"
                    id="pedestriansStreetAddress"
                    label="Street address"
                    {...form?.register("pedestriansStreetAddress")}
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <Input
                    autoComplete="off"
                    id="pedestriansCity"
                    label="City"
                    {...form?.register("pedestriansCity")}
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <Select
                    autoComplete="off"
                    id="pedestriansState"
                    label="State"
                    {...form?.register("pedestriansState")}
                  >
                    <option disabled value="" />
                    {usStates.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </Col>
                <Col xs={12} sm={4}>
                  <Input
                    autoComplete="off"
                    id="pedestriansZip"
                    label="Zip Code"
                    touched={form?.formState.touchedFields.pedestriansZip}
                    error={form?.formState.errors.pedestriansZip}
                    maxLength={10}
                    {...form?.register("pedestriansZip", {
                      onChange: () => form?.trigger("pedestriansZip"),
                    })}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Input
                    id="pedestriansHomePhone"
                    label="Home phone number"
                    type="tel"
                    touched={form?.formState.touchedFields.pedestriansHomePhone}
                    error={form?.formState.errors.pedestriansHomePhone}
                    {...form?.register("pedestriansHomePhone", {
                      onChange: () => form?.trigger("pedestriansHomePhone"),
                    })}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Input
                    id="pedestriansCellPhone"
                    label="Cell phone number"
                    type="tel"
                    touched={form?.formState.touchedFields.pedestriansCellPhone}
                    error={form?.formState.errors.pedestriansCellPhone}
                    {...form?.register("pedestriansCellPhone", {
                      onChange: () => form?.trigger("pedestriansCellPhone"),
                    })}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Input
                    id="pedestriansWorkPhone"
                    label="Work phone number"
                    type="tel"
                    touched={form?.formState.touchedFields.pedestriansWorkPhone}
                    error={form?.formState.errors.pedestriansWorkPhone}
                    {...form?.register("pedestriansWorkPhone", {
                      onChange: () => form?.trigger("pedestriansWorkPhone"),
                    })}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Input
                    autoComplete="off"
                    error={form?.formState.errors.pedestriansEmail}
                    id="pedestriansEmail"
                    label="Email"
                    touched={form?.formState.touchedFields.pedestriansEmail}
                    type="email"
                    {...form?.register("pedestriansEmail", {
                      onBlur: () => form?.trigger("pedestriansEmail"),
                    })}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Input
                    autoComplete="off"
                    error={form?.formState.errors.pedestrianDateOfBirth}
                    touched={
                      form?.formState.touchedFields.pedestrianDateOfBirth
                    }
                    id="pedestrianDateOfBirth"
                    label="Date of birth"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    min="1900-01-01"
                    {...form?.register("pedestrianDateOfBirth", {
                      onBlur: () => form?.trigger("pedestrianDateOfBirth"),
                    })}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <RadioGroup
                    form={form}
                    label="Was this person injured?"
                    name="pedestriansInjured"
                  >
                    <span value="Yes">Yes</span>
                    <span value="No">No</span>
                  </RadioGroup>
                </Col>
                {pedestriansInjured === "Yes" && (
                  <Row>
                    <Col xs={12} sm={6}>
                      <Textarea
                        id="pedestriansInjuryDescription"
                        label="Describe Injuries"
                        maxLength={maxLength}
                        name="pedestriansInjuryDescription"
                        rows={3}
                        form={form}
                        {...form?.register("pedestriansInjuryDescription")}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <RadioGroup
                        form={form}
                        label="Did they seek medical treatment?"
                        name="pedestrianDidSeekMedical"
                      >
                        <span value="Yes">Yes</span>
                        <span value="No">No</span>
                        <span value="Unknown">Unknown</span>
                      </RadioGroup>
                    </Col>
                  </Row>
                )}
              </Row>
            </>
          )}
        </Row>
      </Fieldset>
    </>
  );
};

export default Screen;
