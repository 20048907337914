import React from "react";
import clsx from "clsx";
import { Container } from "react-bootstrap";

const DividerBlock = ({ className, data, ...rest }) => (
  <div className={clsx("DividerBlock", className)} {...rest}>
    <Container>
      <hr className="DividerBlock__hr" />
    </Container>
  </div>
);

export default DividerBlock;
