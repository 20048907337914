import React, { useCallback } from "react";
import clsx from "clsx";
import CompanyInfo from "../../../CompanyInfo/CompanyInfo";
import { Dialog, Menu } from "@headlessui/react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import Helpers from "../../../../utils/Helpers";

const MainLinks = ({
  areMainLinksVisible,
  announcementBannerHeight,
  companyInfo,
  className,
  mainLinks,
  setMainLinksVisibility,
}) => {
  const handleClick = useCallback(
    () => setMainLinksVisibility((visibility) => !visibility),
    [setMainLinksVisibility],
  );

  return (
    <>
      <Dialog
        className={clsx("MainLinks__platform", className)}
        onClose={setMainLinksVisibility}
        open={areMainLinksVisible}
        style={{
          top: `${announcementBannerHeight}px`,
          height: `calc(100vh - ${announcementBannerHeight}px)`,
        }}
      >
        <div className="MainLinks__stage">
          <Dialog.Panel className="MainLinks__wrapper">
            <div className="MainLinks">
              {mainLinks.map(({ links, title, url }) => {
                const hasNestedLinks = !url && Boolean(get(links, "length"));
                const linkClassName = clsx("MainLinks__link", {
                  hasNestedLinks,
                });

                return (
                  <React.Fragment key={title}>
                    {!hasNestedLinks && !Helpers.linkChecker(url) ? (
                      <Link className={linkClassName} to={url}>
                        {title}
                      </Link>
                    ) : (
                      <Menu>
                        {({ open }) => (
                          <>
                            <Menu.Button
                              className={clsx(linkClassName, { open })}
                            >
                              {title}
                              <span
                                aria-hidden="true"
                                className="MainLinks__link__fauxIcon"
                              />
                            </Menu.Button>
                            <Menu.Items className="MainLinks__nestedLinks">
                              {links.map(({ title, url }) => (
                                <Menu.Item key={title}>
                                  {Helpers.linkChecker(url) ? (
                                    <a
                                      href={url}
                                      className="MainLinks__nestedLink"
                                      key={title}
                                    >
                                      {title}
                                    </a>
                                  ) : (
                                    <Link
                                      className="MainLinks__nestedLink"
                                      to={url}
                                      key={title}
                                    >
                                      {title}
                                    </Link>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </>
                        )}
                      </Menu>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <CompanyInfo
              className="MainLinks__companyInfo"
              companyInfo={companyInfo}
            />
          </Dialog.Panel>
        </div>
      </Dialog>
      <div className="MainLinks__visibilityToggleButton__wrapper">
        <button
          className={clsx("MainLinks__visibilityToggleButton", {
            areMainLinksVisible,
          })}
          onClick={handleClick}
          type="button"
          {...(areMainLinksVisible && { style: { pointerEvents: "none" } })}
        >
          <span className="MainLinks__visibilityToggleButton__label">Menu</span>
          <span
            aria-hidden="true"
            className="MainLinks__visibilityToggleButton__fauxIcon"
          />
        </button>
      </div>
    </>
  );
};

export default MainLinks;
