import React, { useMemo } from "react";
import clsx from "clsx";
import { get, has } from "lodash";

const BackgroundImage = ({ className, image }) => {
  const [alternativeText, formats] = useMemo(
    () => [
      get(image, "data.attributes.alternativeText"),
      get(image, "data.attributes.formats"),
    ],
    [image],
  );

  if (!has(formats, "large.url")) return null;

  return (
    <div className={clsx("BackgroundImage", className)}>
      <img
        alt={alternativeText}
        className="BackgroundImage__img"
        src={formats.large.url}
      />
    </div>
  );
};

export default BackgroundImage;
