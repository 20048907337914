import React, { useState } from "react";
import clsx from "clsx";
import Modal from "../../Modal/Modal";
import HeaderParagraphBlock from "../../PageBlocks/components/ContentBlocks/HeaderParagraphBlock/HeaderParagraphBlock";

const Popup = ({ className, popup }) => {
  const { body, buttonLink, buttonText, header } = popup;
  const [isVisible, setVisibility] = useState(true);

  return (
    <Modal
      className={clsx("Popup", className)}
      onClose={() => setVisibility(false)}
      open={isVisible}
    >
      <HeaderParagraphBlock
        data={{
          body,
          buttonText,
          buttonLink,
          header,
        }}
      />
    </Modal>
  );
};

export default Popup;
