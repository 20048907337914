import * as yup from "yup";

const schema = {
  vehicleYear: yup.string().default("").required("This field is required."),
  vehicleMake: yup.string().default("").required("This field is required."),
  vehicleModel: yup.string().default("").required("This field is required."),
  licensePlateNumber: yup.string().default(""),
  vehicleColor: yup.string().default(""),
  vehicleVinNumber: yup
    .string()
    .default("")
    .matches(
      /^([(A-H|J-N|P|R-Z|0-9)]{17})?$/,
      "Please enter a valid VIN number.",
    ),
};

export default schema;
