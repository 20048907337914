import * as yup from "yup";
const max = 1000;
const message = `Your injury description may not be longer than ${max.toLocaleString()} characters.`;

const schema = {
  numberOfClaimants: yup
    .string()
    .default("")
    .required("This field is required."),
  claimants: yup.array().of(
    yup.object().shape({
      firstName: yup.string(),
      lastName: yup.string(),
      streetAddress: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zip: yup.string(),
      homePhone: yup.string(),
      workPhone: yup.string(),
      cellPhone: yup.string(),
      email: yup.string(),
      dateOfBirth: yup.string(),
      wasClaimantMinor: yup.string(),
      parentFirstName: yup.string(),
      parentLastName: yup.string(),
      parentStreetAddress: yup.string(),
      parentCity: yup.string(),
      parentState: yup.string(),
      parentZip: yup.string(),
      parentHomePhone: yup.string(),
      parentWorkPhone: yup.string(),
      parentCellPhone: yup.string(),
      parentEmail: yup.string(),
      insuranceCompanyName: yup.string(),
      insurancePolicyNumber: yup.string(),
      propertyDamageDescription: yup.string().default("").max(max, message),
      relationshipToInsured: yup.string(),
      wasClaimantInjured: yup.string(),
      claimantInjuryDescription: yup.string().default("").max(max, message),
      didClaimantSeekMedical: yup.string(),
    }),
  ),
};

export default schema;
export { max };
