import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { Container } from "react-bootstrap";
import Input from "../../../../Input/Input";
import Button from "../../../../Button/Button";

const FindLinksBlock = ({ as, className, data, headerAs, ...rest }) => {
  const Tag = useMemo(() => as || "section", [as]);
  const [portalLink, setPortalLink] = useState("");

  const getLink = (policyNumber) => {
    if (!policyNumber.substring(0, 4).match(/[0-9]/i)) {
      return data.legacyLoginLink;
    } else if (!policyNumber.substring(0, 3).match(/[0-9]/i)) {
      return data.newLoginLink;
    } else {
      return "";
    }
  };
  return (
    <Tag className={clsx("PolicyFormBlock__wrapper", className)} {...rest}>
      <Container>
        <div className="PolicyFormBlock__content">
          <div className="PolicyFormBlock__form__wrapper">
            <Input id="policyNumber" label="Please enter your Policy number:" />
            <div className="PolicyFormBlock__button__wrapper">
              <Button
                onClick={() => {
                  setPortalLink(
                    getLink(document.getElementById("policyNumber").value),
                  );
                }}
              >
                Find links
              </Button>
            </div>
          </div>
          {portalLink !== "" && (
            <div className="PolicyFormBlock__content__wrapper">
              <span>Here is your portal link based on your Policy number:</span>
              <a className="PolicyFormBlock__link" href={portalLink}>
                {portalLink}
              </a>
            </div>
          )}
        </div>
      </Container>
    </Tag>
  );
};

export default FindLinksBlock;
