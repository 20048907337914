import React from "react";
import clsx from "clsx";
import { Container } from "react-bootstrap";
import { Dialog } from "@headlessui/react";
import Header from "../Header/Header";

const Modal = ({
  children,
  className,
  isCloseButtonVisible = true,
  panelClassName,
  onClose,
  open,
  title,
  titleClassName,
  width,
  ...rest
}) => (
  <Dialog
    className={clsx("Modal", className, { open })}
    onClose={onClose}
    open={open}
    {...rest}
  >
    <Container style={{ width: width }}>
      <Dialog.Panel className={clsx("Modal__panel", panelClassName)}>
        {isCloseButtonVisible && (
          <div className="Modal__closeButton__wrapper">
            <button
              className="Modal__closeButton"
              onClick={onClose}
              type="button"
            >
              <span className="visually-hidden">Close</span>
              <span
                aria-hidden="true"
                className="Modal__closeButton__fauxIcon"
              />
            </button>
          </div>
        )}

        {title && (
          <Dialog.Title
            as={Header}
            className={clsx("Modal__title", titleClassName)}
            header={title}
          />
        )}

        {children}
      </Dialog.Panel>
    </Container>
  </Dialog>
);

export default Modal;
