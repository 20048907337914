import React, { useMemo } from "react";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";
import { Accordion, Container } from "react-bootstrap";

const FaqAccordionBlock = ({
  as,
  className,
  data,
  headerAs,
  hideHeader,
  ...rest
}) => {
  const isVisible = useMemo(() => data.faqs.length > 0, [data]);

  if (!isVisible) return null;

  return (
    <section className={clsx("FaqAccordionBlock__wraper", className)} {...rest}>
      <Container>
        <div className="FaqAccordionBlock">
          {data.header && !hideHeader && (
            <h2 className="FaqAccordionBlock__header">{data.header}</h2>
          )}
          {data.body && !hideHeader && (
            <Markdown
              className="FaqAccordionBlock__body"
              options={{ forceBlock: true }}
            >
              {data.body}
            </Markdown>
          )}
          <Accordion
            defaultActiveKey="0"
            className="FaqAccordionBlock__accordion"
          >
            {data.faqs.map(({ body, header, id }) => (
              <React.Fragment key={id}>
                {header && body && (
                  <Accordion.Item
                    key={id}
                    eventKey={id}
                    className="FaqAccordionBlock__accordion__item"
                  >
                    <Accordion.Header className="FaqAccordionBlock__accordion__header">
                      <h3>{header}</h3>
                    </Accordion.Header>
                    <Accordion.Body className="FaqAccordionBlock__accordion__body">
                      <Markdown options={{ forceBlock: true }}>{body}</Markdown>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </React.Fragment>
            ))}
          </Accordion>
        </div>
      </Container>
    </section>
  );
};

export default FaqAccordionBlock;
