import React, { forwardRef } from "react";
import clsx from "clsx";
import Wrapper from "./components/Wrapper";

const Button = forwardRef(
  ({ children, className, isSmall, to = null, ...rest }, ref) => (
    <Wrapper
      className={clsx("Button", className, { isSmall })}
      to={to}
      ref={ref}
      {...rest}
    >
      {children}
    </Wrapper>
  ),
);

Button.displayName = "Button";

export default Button;
