import * as yup from "yup";

const schema = {
  otherVehicleOwnerFirstName: yup.string().default(""),
  otherVehicleOwnerMiddleName: yup.string().default(""),
  otherVehicleOwnerLastName: yup.string().default(""),
  otherVehicleOwnerStreetAddress: yup.string().default(""),
  otherVehicleOwnerCity: yup.string().default(""),
  otherVehicleOwnerState: yup.string().default(""),
  otherVehicleOwnerZip: yup
    .string()
    .default("")
    .matches(
      /^(?:\d{5}(?:[-\s]?\d{4})?|\s*)$/,
      "Please enter a valid zip code.",
    ),
  otherVehicleOwnerHomePhone: yup
    .string()
    .default("")
    .matches(
      /^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/,
      "Please enter a valid phone number.",
    ),
  otherVehicleOwnerCellPhone: yup
    .string()
    .default("")
    .matches(
      /^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/,
      "Please enter a valid phone number.",
    ),
  otherVehicleOwnerWorkPhone: yup
    .string()
    .default("")
    .matches(
      /^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/,
      "Please enter a valid phone number.",
    ),
  otherVehicleOwnerEmail: yup
    .string()
    .default("")
    .email("Please enter a valid email address."),
};

export default schema;
