import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Fieldset from "../../../../../../../../Fieldset/Fieldset";
import Input from "../../../../../../../../Input/Input";
import Select from "../../../../../../../../Select/Select";
import usStates from "../../../us_states.json";
import useScreen from "../hooks/useScreen";

const Screen = ({ form, screenIndex, wizard }) => {
  const policyHolderFirstNameValue = form?.watch("policyHolderFirstName");
  const policyHolderLastNameValue = form?.watch("policyHolderLastName");
  const emailValidation = form?.formState.errors.policyHolderEmail;
  const homePhoneValidation = form?.formState.errors.policyHolderHomePhone;
  const workPhoneValidation = form?.formState.errors.policyHolderWorkPhone;
  const cellPhoneValidation = form?.formState.errors.policyHolderCellPhone;
  const zipValidation = form?.formState.errors.policyHolderZip;

  const isInvalid = useMemo(() => {
    if (!policyHolderFirstNameValue || !policyHolderLastNameValue) return true;
    if (
      emailValidation ||
      homePhoneValidation ||
      workPhoneValidation ||
      cellPhoneValidation ||
      zipValidation
    )
      return true;
    return false;
  }, [
    policyHolderFirstNameValue,
    policyHolderLastNameValue,
    emailValidation,
    homePhoneValidation,
    workPhoneValidation,
    cellPhoneValidation,
    zipValidation,
  ]);

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <>
      <Fieldset legend="Policy Holder&rsquo;s Information">
        <Row>
          <Col xs={12} sm={6}>
            <Input
              autoComplete="off"
              error={form?.formState.errors.policyHolderFirstName}
              id="policyHolderFirstName"
              label="First name"
              touched={form?.formState.touchedFields.policyHolderFirstName}
              {...form?.register("policyHolderFirstName", {
                onBlur: () => form?.trigger("policyHolderFirstName"),
              })}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Input
              autoComplete="off"
              id="policyHolderMiddleName"
              label="Middle name"
              {...form?.register("policyHolderMiddleName")}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Input
              autoComplete="off"
              error={form?.formState.errors.policyHolderLastName}
              id="policyHolderLastName"
              label="Last name"
              touched={form?.formState.touchedFields.policyHolderLastName}
              {...form?.register("policyHolderLastName", {
                onBlur: () => form?.trigger("policyHolderLastName"),
              })}
            />
          </Col>
        </Row>
        <Input
          autoComplete="off"
          id="policyHolderStreetAddress"
          label="Street address"
          {...form?.register("policyHolderStreetAddress")}
        />
        <Row>
          <Col xs={12} sm={4}>
            <Input
              autoComplete="off"
              id="policyHolderCity"
              label="City"
              {...form?.register("policyHolderCity")}
            />
          </Col>
          <Col xs={12} sm={4}>
            <Select
              autoComplete="off"
              id="policyHolderState"
              label="State"
              {...form?.register("policyHolderState")}
            >
              <option disabled value="" />
              {usStates.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Select>
          </Col>
          <Col xs={12} sm={4}>
            <Input
              autoComplete="off"
              id="policyHolderZip"
              label="Zip Code"
              maxLength={10}
              touched={form?.formState.touchedFields.policyHolderZip}
              error={form?.formState.errors.policyHolderZip}
              {...form?.register("policyHolderZip", {
                onChange: () => form?.trigger("policyHolderZip"),
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Input
              id="policyHolderHomePhone"
              label="Home phone number"
              type="tel"
              touched={form?.formState.touchedFields.policyHolderHomePhone}
              error={form?.formState.errors.policyHolderHomePhone}
              {...form?.register("policyHolderHomePhone", {
                onChange: () => form?.trigger("policyHolderHomePhone"),
              })}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Input
              id="policyHolderCellPhone"
              label="Cell phone number"
              type="tel"
              touched={form?.formState.touchedFields.policyHolderCellPhone}
              error={form?.formState.errors.policyHolderCellPhone}
              {...form?.register("policyHolderCellPhone", {
                onChange: () => form?.trigger("policyHolderCellPhone"),
              })}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Input
              id="policyHolderWorkPhone"
              label="Work phone number"
              type="tel"
              touched={form?.formState.touchedFields.policyHolderWorkPhone}
              error={form?.formState.errors.policyHolderWorkPhone}
              {...form?.register("policyHolderWorkPhone", {
                onChange: () => form?.trigger("policyHolderWorkPhone"),
              })}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Input
              autoComplete="off"
              error={form?.formState.errors.policyHolderEmail}
              id="policyHolderEmail"
              label="Email"
              touched={form?.formState.touchedFields.policyHolderEmail}
              type="email"
              {...form?.register("policyHolderEmail", {
                onBlur: () => form?.trigger("policyHolderEmail"),
              })}
            />
          </Col>
        </Row>
      </Fieldset>
    </>
  );
};

export default Screen;
