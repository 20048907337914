import React, { useEffect } from "react";

import { initGA } from "../../utils/ga-utils";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };
  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <CookieConsent
      enableDeclineButton
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
      location="bottom"
      buttonText="Accept All"
      declineButtonText="Decline"
      overlay="true"
      acceptOnOverlayClick="true"
      declineButtonClasses="CookieConsent__DeclineButton CookieConsent__Button"
      contentClasses="CookieConsent__Content"
      disableStyles="true"
      buttonClasses="CookieConsent__AcceptButton CookieConsent__Button"
      buttonWrapperClasses="CookieConsent__ButtonWrapper"
      overlayClasses="CookieConsent__Overlay"
    >
      <h3>We value your privacy</h3>
      <p>
        We use cookies to enhance your browsing experience, serve personalized
        ads or content, and analyze our traffic. By clicking "Accept All", you
        consent to our use of cookies.
      </p>
      <Link to="/privacy-policy">Privacy Policy</Link>
    </CookieConsent>
  );
};

export default CookiePolicy;
