import React, { useMemo } from "react";
import clsx from "clsx";
import parse from "html-react-parser";
import Helpers from "../../utils/Helpers";

const Header = ({ as, className, header }) => {
  const Tag = useMemo(() => as || "h2", [as]);
  const [isLongHeader, parsedHeader] = useMemo(
    () => [header.length > 65, parse(Helpers.preventOrphans(header))],
    [header],
  );

  return (
    <Tag className={clsx("Header", className, { isLongHeader })}>
      {parsedHeader}
    </Tag>
  );
};

export default Header;
