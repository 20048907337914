import * as yup from "yup";
const max = 1000;

const schema = {
  isAnotherVehicleInvolved: yup
    .string()
    .default("")
    .required("This field is required."),
  otherVehicleYear: yup.string().default(""),
  otherVehicleMake: yup.string().default(""),
  otherVehicleModel: yup.string().default(""),
  otherVehicleLicensePlateNumber: yup.string().default(""),
  otherVehicleVehicleColor: yup.string().default(""),
  otherVehicleVehicleVin: yup
    .string()
    .default("")
    .matches(
      /^([(A-H|J-N|P|R-Z|0-9)]{17})?$/,
      "Please enter a valid VIN number.",
    ),
  isOtherVehicleDamaged: yup.string().default(""),
  otherVehicleDamageDescription: yup
    .string()
    .default("")
    .when("isAnotherVehicleInvolved", {
      is: "Yes",
      then: yup.string().required("This field is required."),
      otherwise: yup.string().default(""),
    }),
  otherVehicleInitialPointOfImpact: yup
    .string()
    .default("")
    .when("isAnotherVehicleInvolved", {
      is: "Yes",
      then: yup.string().required("This field is required."),
      otherwise: yup.string().default(""),
    }),
  otherVehicleUnrelatedPriorDamage: yup.string().default(""),
  isOtherVehicleDrivable: yup.string().default(""),
  otherVehicleLocationStreetAddress: yup.string().default(""),
  otherVehicleLocationCity: yup.string().default(""),
  otherVehicleLocationState: yup.string().default(""),
  otherVehicleLocationZip: yup
    .string()
    .default("")
    .matches(
      /^(?:\d{5}(?:[-\s]?\d{4})?|\s*)$/,
      "Please enter a valid zip code.",
    ),
  isOtherVehicleBusinessAddress: yup.string().default(""),
  OtherVehicleBusinessName: yup.string().default(""),
  OtherVehicleBusinessPhone: yup.string().default(""),
  OtherVehicleInsuranceCompanyName: yup.string().default(""),
  otherVehicleInsuranceCompanyPolicyNumber: yup.string().default(""),
};

export default schema;
export { max };
