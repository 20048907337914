import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

const Wrapper = forwardRef(({ children, to, ...rest }, ref) => {
  const isExternal = to && to.startsWith("http");

  if (to) {
    return isExternal ? (
      <a href={to} ref={ref} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    ) : (
      <Link to={to} ref={ref} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <aside ref={ref} {...rest}>
      {children}
    </aside>
  );
});

Wrapper.displayName = "Wrapper";

export default Wrapper;
