import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Fieldset from "../../../../../../../../Fieldset/Fieldset";
import Input from "../../../../../../../../Input/Input";
import Select from "../../../../../../../../Select/Select";
import usStates from "../../../us_states.json";
import useScreen from "../hooks/useScreen";

import RadioGroup from "../../../../../../../../RadioGroup/RadioGroup";
import { max as maxLength } from "./schema";
import Textarea from "../../../../../../../../Textarea/Textarea";

const Screen = ({ form, screenIndex, wizard }) => {
  const fixedObjectInvolved = form?.watch("fixedObjectInvolved");
  const emailValidation = form?.formState.errors.fixedObjectOwnersEmail;
  const phoneValidation = form?.formState.errors.fixedObjectOwnersPhone;
  const fixedObjectDescription = form?.watch("fixedObjectDescription");
  const zipValidation = form?.formState.errors.fixedObjectOwnersZip;

  const isInvalid = useMemo(() => {
    if (!fixedObjectInvolved) return true;
    if (fixedObjectInvolved === "Yes") {
      if (!fixedObjectDescription) return true;
      if (emailValidation || phoneValidation || zipValidation) return true;
    }
    return false;
  }, [
    fixedObjectInvolved,
    emailValidation,
    phoneValidation,
    fixedObjectDescription,
    zipValidation,
  ]);

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <>
      <Fieldset legend="Fixed Object Information">
        <Row>
          <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Was a fixed object involved?"
              name="fixedObjectInvolved"
              required
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          {fixedObjectInvolved === "Yes" && (
            <>
              <Row>
                <Col xs={12} sm={6}>
                  <Textarea
                    id="fixedObjectDescription"
                    label="Describe Fixed Object"
                    maxLength={maxLength}
                    name="fixedObjectDescription"
                    rows={3}
                    form={form}
                    error={form?.formState.errors.fixedObjectDescription}
                    touched={
                      form?.formState.touchedFields.fixedObjectDescription
                    }
                    {...form?.register("fixedObjectDescription", {
                      onChange: () => form?.trigger("fixedObjectDescription"),
                      required: "This field is required.",
                    })}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Textarea
                    id="fixedObjectDamage"
                    label="Describe Damage to Fixed Object"
                    maxLength={maxLength}
                    name="fixedObjectDamage"
                    rows={3}
                    form={form}
                    {...form?.register("fixedObjectDamage")}
                  />
                </Col>
              </Row>

              <Fieldset legend="Fixed Object Owner Information">
                <Row>
                  <Col xs={12} sm={6}>
                    <Input
                      autoComplete="off"
                      error={form?.formState.errors.fixedObjectOwnersFirstName}
                      id="fixedObjectOwnersFirstName"
                      label="First name"
                      touched={
                        form?.formState.touchedFields.fixedObjectOwnersFirstName
                      }
                      {...form?.register("fixedObjectOwnersFirstName", {
                        onBlur: () =>
                          form?.trigger("fixedObjectOwnersFirstName"),
                      })}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      autoComplete="off"
                      error={form?.formState.errors.fixedObjectOwnersLastName}
                      id="fixedObjectOwnersLastName"
                      label="Last name"
                      touched={
                        form?.formState.touchedFields.fixedObjectOwnersLastName
                      }
                      {...form?.register("fixedObjectOwnersLastName", {
                        onBlur: () =>
                          form?.trigger("fixedObjectOwnersLastName"),
                      })}
                    />
                  </Col>
                  <Col xs={12} sm={12}>
                    <Input
                      autoComplete="off"
                      id="fixedObjectOwnersStreetAddress"
                      label="Street address"
                      {...form?.register("fixedObjectOwnersStreetAddress")}
                    />
                  </Col>
                  <Col xs={12} sm={4}>
                    <Input
                      autoComplete="off"
                      id="fixedObjectOwnersCity"
                      label="City"
                      {...form?.register("fixedObjectOwnersCity")}
                    />
                  </Col>
                  <Col xs={12} sm={4}>
                    <Select
                      autoComplete="off"
                      id="fixedObjectOwnersState"
                      label="State"
                      {...form?.register("fixedObjectOwnersState")}
                    >
                      <option disabled value="" />
                      {usStates.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={12} sm={4}>
                    <Input
                      autoComplete="off"
                      id="fixedObjectOwnersZip"
                      label="Zip Code"
                      maxLength={10}
                      touched={
                        form?.formState.touchedFields.fixedObjectOwnersZip
                      }
                      error={form?.formState.errors.fixedObjectOwnersZip}
                      {...form?.register("fixedObjectOwnersZip", {
                        onChange: () => form?.trigger("fixedObjectOwnersZip"),
                      })}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      autoComplete="off"
                      id="fixedObjectOwnersPhone"
                      label="Fixed Object Owner's Phone"
                      type="tel"
                      touched={
                        form?.formState.touchedFields.fixedObjectOwnersPhone
                      }
                      error={form?.formState.errors.fixedObjectOwnersPhone}
                      {...form?.register("fixedObjectOwnersPhone", {
                        onChange: () => form?.trigger("fixedObjectOwnersPhone"),
                      })}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input
                      autoComplete="off"
                      error={form?.formState.errors.fixedObjectOwnersEmail}
                      id="fixedObjectOwnersEmail"
                      label="Email"
                      touched={
                        form?.formState.touchedFields.fixedObjectOwnersEmail
                      }
                      type="email"
                      {...form?.register("fixedObjectOwnersEmail", {
                        onChange: () => form?.trigger("fixedObjectOwnersEmail"),
                      })}
                    />
                  </Col>
                </Row>
              </Fieldset>
            </>
          )}
        </Row>
      </Fieldset>
    </>
  );
};

export default Screen;
