import React, {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import clsx from "clsx";
import { range } from "lodash";
import Button from "../Button/Button";

const Wizard = ({ children, form }) => {
  const wizardChildren = Children.toArray(children);
  const [validScreens, setValidScreens] = useState([]);
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const [isCurrentScreenValid, setIsCurrentScreenValid] = useState(true);
  const isAnotherVehicleInvolved = form?.watch("isAnotherVehicleInvolved");

  const update = useCallback(
    (isValid, index) => {
      setCurrentScreenIndex(index);
      setIsCurrentScreenValid(isValid);
    },
    [setCurrentScreenIndex, setIsCurrentScreenValid],
  );

  useEffect(
    () =>
      setValidScreens((prevScreens) => [
        ...prevScreens.slice(0, currentScreenIndex),
        isCurrentScreenValid,
        ...prevScreens.slice(currentScreenIndex + 1),
      ]),
    [currentScreenIndex, isCurrentScreenValid],
  );

  return (
    <div className="Wizard__wrapper">
      <div className="Wizard">
        <div className="Wizard__screens">
          {Children.map(wizardChildren, (child, i) => {
            const isCurrentScreen = i === currentScreenIndex;

            return (
              <div
                className="Wizard__screen"
                style={{
                  transform: `translate3d(${currentScreenIndex * 100 * -1}%, 0, 0)`,
                }}
              >
                {isCurrentScreen &&
                  cloneElement(child, {
                    screenIndex: i,
                    wizard: {
                      currentScreenIndex,
                      update,
                    },
                  })}
              </div>
            );
          })}
        </div>
      </div>

      {wizardChildren.length > 1 && (
        <>
          <div className="Wizard__arrows">
            <Button
              className="Wizard__arrows__button"
              disabled={!isCurrentScreenValid || currentScreenIndex === 15}
              isSmall
              onClick={() =>
                currentScreenIndex === 8 && isAnotherVehicleInvolved === "No"
                  ? setCurrentScreenIndex((prevIndex) => prevIndex + 4)
                  : setCurrentScreenIndex((prevIndex) => prevIndex + 1)
              }
              type="button"
            >
              Next
            </Button>
            <Button
              className="Wizard__arrows__button"
              disabled={currentScreenIndex === 0}
              isSmall
              onClick={() =>
                setCurrentScreenIndex((prevIndex) => prevIndex - 1)
              }
              type="button"
            >
              &larr;
              <span className="visually-hidden">Prev</span>
            </Button>
          </div>
          <ul className="Wizard__pagination">
            {range(wizardChildren.length).map((i) =>
              (() => {
                const indexedValidScreens = validScreens.length - 1;

                return (
                  <li key={i}>
                    <button
                      className={clsx("Wizard__pagination__button", {
                        isActive: i === currentScreenIndex,
                      })}
                      disabled={
                        i > indexedValidScreens ||
                        (!isCurrentScreenValid && i >= indexedValidScreens)
                      }
                      onClick={() => setCurrentScreenIndex(i)}
                      tabIndex={-1}
                      type="button"
                    />
                  </li>
                );
              })(),
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default Wizard;
