import * as yup from "yup";

const max = 1000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  fixedObjectInvolved: yup
    .string()
    .default("")
    .required("This field is required"),
  fixedObjectDescription: yup
    .string()
    .default("")
    .max(max, message)
    .when("fixedObjectInvolved", {
      is: "Yes",
      then: yup.string().required("This field is required."),
      otherwise: yup.string().default(""),
    }),
  fixedObjectDamage: yup.string().default("").max(max, message),
  fixedObjectOwnersFirstName: yup.string().default(""),
  fixedObjectOwnersMiddleName: yup.string().default(""),
  fixedObjectOwnersLastName: yup.string().default(""),
  fixedObjectOwnersStreetAddress: yup.string().default(""),
  fixedObjectOwnersCity: yup.string().default(""),
  fixedObjectOwnersState: yup.string().default(""),
  fixedObjectOwnersZip: yup
    .string()
    .default("")
    .matches(
      /^(?:\d{5}(?:[-\s]?\d{4})?|\s*)$/,
      "Please enter a valid zip code.",
    ),
  fixedObjectOwnersPhone: yup
    .string()
    .default("")
    .matches(
      /^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/,
      "Please enter a valid phone number.",
    ),
  fixedObjectOwnersCellPhone: yup
    .string()
    .default("")
    .matches(
      /^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/,
      "Please enter a valid phone number.",
    ),
  fixedObjectOwnersEmail: yup
    .string()
    .default("")
    .email("Please enter a valid email address."),
};

export default schema;
export { max };
