import * as yup from "yup";

const max = 1000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  otherVehicleDriversSameAsVehicleOwner: yup.string().default(""),
  otherVehicleDriversFirstName: yup
    .string()
    .default("")
    .when("otherVehicleDriversSameAsVehicleOwner", {
      is: "No",
      then: yup.string().required("This field is required."),
      otherwise: yup.string().default(""),
    }),
  otherVehicleDriversMiddleName: yup.string().default(""),
  otherVehicleDriversLastName: yup
    .string()
    .default("")
    .when("otherVehicleDriversSameAsVehicleOwner", {
      is: "No",
      then: yup.string().required("This field is required."),
      otherwise: yup.string().default(""),
    }),
  otherVehicleDriversStreetAddress: yup.string().default(""),
  otherVehicleDriversCity: yup.string().default(""),
  otherVehicleDriversState: yup.string().default(""),
  otherVehicleDriversZip: yup.string().default(""),
  otherVehicleDriversHomePhone: yup
    .string()
    .default("")
    .matches(
      /^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/,
      "Please enter a valid phone number.",
    ),
  otherVehicleDriversCellPhone: yup
    .string()
    .default("")
    .matches(
      /^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/,
      "Please enter a valid phone number.",
    ),
  otherVehicleDriversWorkPhone: yup
    .string()
    .default("")
    .matches(
      /^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/,
      "Please enter a valid phone number.",
    ),
  otherVehicleDriversEmail: yup
    .string()
    .default("")
    .email("Please enter a valid email address."),
  otherVehicleDriversDateOfBirth: yup
    .string()
    .default("")
    .test("dateOfBirth", "Please enter a valid date of birth.", (value) => {
      const date = new Date(value);
      const minYear = 1900;
      const maxYear = new Date().getFullYear();
      const year = date.getFullYear();
      if (year < minYear || year > maxYear) return false;
      return !isNaN(date.getTime()) || value === "";
    }),
  otherVehicleDriversLicenseNumber: yup.string().default(""),
  otherVehicleDriversLicenseState: yup.string().default(""),
  otherVehicleDriversDidReceiveTicket: yup.string().default(""),
  otherVehicleDriversTicketReason: yup.string().default("").max(max, message),
  otherVehicleDriversInjured: yup.string().default(""),
  otherVehicleDriversInjuryDescription: yup
    .string()
    .default("")
    .max(max, message),
  otherVehicleDriversDidSeekMedical: yup.string().default(""),
};

export default schema;
export { max };
