import React, { useCallback, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Button from "../../../../../Button/Button";
import Input from "../../../../../Input/Input";
import Select from "../../../../../Select/Select";
import Textarea from "../../../../../Textarea/Textarea";
import { AxiosError } from "axios";
import useScript from "../../../../hooks/useScript";
import { sendEmail } from "../../../../../../utils/endpoints";
import Modal from "../../../../../Modal/Modal";

const Form = ({ className }) => {
  const recaptchaApiKey = process.env.REACT_APP_RECAPTCHA_API_KEY;

  const { grecaptcha } = useScript(
    `https://www.google.com/recaptcha/enterprise.js?render="${recaptchaApiKey}"`,
    "grecaptcha",
  );
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedMessage, setFormSubmittedMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, ...form } = useForm({
    defaultValues: {
      email: "",
      name: "",
      phone: "",
      policyOrClaimNumber: "",
      subject: "",
      summary: "",
    },
  });

  const departmentEmails = {
    billing: "website_form_submission_billing@producersnational.com",
    claims: "website_form_submission_claims@producersnational.com",
    feedback: "marketing@producersnational.com",
    generalInfo: "marketing@producersnational.com",
    marketing: "marketing@producersnational.com",
    policyInfo: "marketing@producersnational.com",
    technicalSupport:
      "website_form_submission_tech_support@producersnational.com",
  };

  const matchDepartmentEmail = (subject) => {
    return departmentEmails[subject];
  };

  const onSubmit = useCallback(
    async (data) => {
      data.form = "contact";
      data.emailTo = matchDepartmentEmail(data.subject);
      setIsLoading(true);
      grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise
          .execute({ action: "submit" })
          .then(async function (token) {
            await sendEmail(data)
              .then((response) => {
                setIsLoading(false);
                setFormSubmitted(true);
                setFormSubmittedMessage(
                  "Thank you for contact us we will be in touch with you soon!",
                );
              })
              .catch((reason = AxiosError) => {
                setIsLoading(false);
                setFormSubmitted(true);
                setFormSubmittedMessage(
                  "There was an error submitting your form. Please refresh the page and try again.",
                );
              });
          })
          .catch((reason = AxiosError) => {
            setFormSubmittedMessage(
              "There was an error submitting your form. Please refresh the page and try again.",
            );
          });
      });
    },
    [grecaptcha],
  );

  return (
    <>
      {!formSubmitted ? (
        <form className={className} onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} md={6}>
              <Select
                error={form.formState.errors.subject}
                form={form}
                id="subject"
                label="Subject*"
                touched={form.formState.touchedFields.subject}
                {...form.register("subject", {
                  required: "You must select an option.",
                })}
              >
                <option disabled value="">
                  Please select an option
                </option>
                <option value="billing">Billing</option>
                <option value="claims">Claims</option>
                <option value="feedback">Feedback</option>
                <option value="generalInfo">General Info</option>
                <option value="marketing">Marketing</option>
                <option value="policyInfo">Policy Info</option>
                <option value="technicalSupport">Technical Support</option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Input
                error={form.formState.errors.name}
                id="name"
                label="Your Name*"
                touched={form.formState.touchedFields.name}
                {...form.register("name", {
                  required: "You must enter your name.",
                })}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                error={form.formState.errors.phone}
                id="phone"
                label="Phone Number*"
                touched={form.formState.touchedFields.phone}
                type="tel"
                {...form.register("phone", {
                  required: "You must enter your phone number.",
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Input
                error={form.formState.errors.policyOrClaimNumber}
                id="policyOrClaimNumber"
                label="Your Policy/Claim Number"
                name="policyOrClaimNumber"
                touched={form.formState.touchedFields.policyOrClaimNumber}
                {...form.register("policyOrClaimNumber")}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                error={form.formState.errors.email}
                id="email"
                label="Your Email*"
                touched={form.formState.touchedFields.email}
                type="email"
                {...form.register("email", {
                  required: "You must enter your email.",
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {(() => {
                const maxLength = 1000;
                return (
                  <Textarea
                    error={form.formState.errors.summary}
                    form={form}
                    id="summary"
                    label="Please enter a brief summary of your comment or issue*"
                    maxLength={maxLength}
                    name="summary"
                    rows={10}
                    trigger={form.trigger}
                    touched={form.formState.touchedFields.summary}
                    watch={form.watch}
                    {...form.register("summary", {
                      maxLength: {
                        value: maxLength,
                        message: `Your summary may not be than ${maxLength.toLocaleString()} characters.`,
                      },
                      required: "You must enter a summary.",
                    })}
                  />
                );
              })()}
            </Col>
          </Row>
          <Button
            className="g-recaptcha"
            data-sitekey={recaptchaApiKey}
            data-size="invisible"
            type="submit"
            style={{ width: "100%" }}
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
          {isLoading && (
            <Modal
              isCloseButtonVisible={false}
              onClose={() => {}}
              open={true}
              width="max-content"
              panelClassName="loading__modal"
            >
              <Spinner animation="grow"> </Spinner>
              <p>Processing your request</p>
            </Modal>
          )}
        </form>
      ) : (
        <h3>{formSubmittedMessage}</h3>
      )}
    </>
  );
};

export default Form;
