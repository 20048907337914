import React, { forwardRef, useEffect, useMemo, useState } from "react";
import "detect-autofill";
import clsx from "clsx";
import { noop, uniqueId } from "lodash";
import InputWrapper from "../InputWrapper/InputWrapper";

const Input = forwardRef(
  (
    {
      autoComplete,
      className,
      error,
      helperText,
      id,
      isLabelHidden,
      label,
      labelId,
      name,
      onAutoComplete = noop,
      onBlur = noop,
      onChange = noop,
      touched,
      type = "text",
      ...rest
    },
    ref,
  ) => {
    const inputId = useMemo(() => id || uniqueId(`input-${type}-`), [id]);
    const inputLabelId = useMemo(
      () => labelId || `${inputId}-label`,
      [labelId, inputId],
    );

    const isAutoCompleteOff = useMemo(
      () => autoComplete === "off",
      [autoComplete],
    );
    const [isAutoCompleted, setAutoCompleted] = useState(false);
    const isTouched = useMemo(
      () => touched || isAutoCompleted,
      [isAutoCompleted, touched],
    );

    useEffect(() => {
      const handleAutoComplete = (e) => {
        if (isAutoCompleteOff || e.target.name !== name) return;

        if (e.target.hasAttribute("autocompleted")) {
          setAutoCompleted(true);
          setTimeout(onAutoComplete, 0);
          return;
        }

        setAutoCompleted(false);
      };

      document.addEventListener("onautocomplete", handleAutoComplete);
      return () =>
        document.removeEventListener("onautocomplete", handleAutoComplete);
    }, [isAutoCompleteOff, name, onAutoComplete]);

    return (
      <InputWrapper
        className={className}
        error={error}
        helperText={helperText}
        isLabelHidden={isLabelHidden}
        label={label}
        labelHtmlFor={id}
        labelId={inputLabelId}
        touched={isTouched}
      >
        <input
          aria-labelledby={inputLabelId}
          autoComplete={autoComplete}
          className={clsx("Input", {
            error: isTouched && error,
            touched: isTouched,
          })}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          type={type}
          {...(touched && { "aria-invalid": error ? "true" : "false" })}
          {...(isAutoCompleteOff && {
            "data-form-type": "other",
            "data-lpignore": "true",
          })}
          {...rest}
        />
      </InputWrapper>
    );
  },
);

Input.displayName = "Input";

export default Input;
