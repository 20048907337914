import { AxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Button from "../../../../../Button/Button";
import Input from "../../../../../Input/Input";
import usStates from "../../ClaimFormBlock/components/us_states.json";
import Select from "../../../../../Select/Select";
import { range } from "lodash";
import Fieldset from "../../../../../Fieldset/Fieldset";
import useScript from "../../../../hooks/useScript";
import {
  makeAPayment,
  oAuthPaytrace,
  saleTransaction,
} from "../../../../../../utils/endpoints";
import Modal from "../../../../../Modal/Modal";

const CreditCardForm = ({
  className,
  amount,
  claimNumber,
  policyKey,
  policyNumber,
  setFormSubmittedPolicy,
}) => {
  const recaptchaApiKey = process.env.REACT_APP_RECAPTCHA_API_KEY;
  const integrator_id = process.env.REACT_APP_PAYTRACE_INTEGRATOR_ID;
  const protectEnv = process.env.REACT_APP_PROTECT_ENV_URL;

  const { PTPayment } = useScript(
    `${protectEnv}/js/protect.min.js`,
    "PTPayment",
  );

  const { paytrace } = useScript(
    "https://api.paytrace.com/assets/e2ee/paytrace-e2ee.js",
    "paytrace",
  );
  const { grecaptcha } = useScript(
    `https://www.google.com/recaptcha/enterprise.js?render="${recaptchaApiKey}"`,
    "grecaptcha",
  );

  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedMessage, setFormSubmittedMessage] = useState("");
  const [formSubmittedWarning, setFormSubmittedWarning] = useState("");
  const [paytraceInstance, setPaytraceInstance] = useState(null);
  const [paytraceError, setPaytraceError] = useState([]);
  const [oAuthToken, setOAuthToken] = useState("");
  const [clientKey, setClientKey] = useState("");
  const [updateInstance, setUpdateInstance] = useState(false);

  const currentYear = 2100;
  const { handleSubmit, ...form } = useForm({
    defaultValues: {
      delete_cc_on_success: true,
      card_number: "",
      expiration_date: "",
      security_code: "",
      billing_address_city: "",
      billing_address_country: "US",
      billing_address_display: "",
      billing_address_first_name: "",
      billing_address_last_name: "",
      billing_address_state: "",
      billing_address_zip: "",
    },
  });

  useEffect(() => {
    if (oAuthToken === "") {
      oAuthPaytrace(claimNumber).then((response) => {
        setOAuthToken(response?.data?.access_token);
        setClientKey(response?.data?.clientKey);
        setUpdateInstance(true);
      });
    }
  }, [oAuthToken]);

  useEffect(() => {
    if (!PTPayment && clientKey === "" && !updateInstance) return;
    PTPayment.setup({
      authorization: {
        clientKey: clientKey,
      },
      styles: {
        code: {
          label_font: '"Montserrat", sans-serif',
          input_font: "Montserrat, sans-serif",
          background_color: "#efefef",
          border_style: "none",
          height: "30px",
          font_size: "16px",
          input_margin: "0.7em 0.7em 0em 0.7em",
          input_border_radius: "3px",
          label_color: "#1f2021",
        },
        cc: {
          label_font: '"Montserrat", sans-serif',
          input_font: "Montserrat, sans-serif",
          background_color: "#efefef",
          border_style: "none",
          height: "30px",
          font_size: "16px",
          input_margin: "0.7em ",
          input_border_radius: "3px",
          label_color: "#1f2021",
          width: "97%",
        },
        exp: {
          label_font: '"Montserrat", sans-serif',
          input_font: "Montserrat, sans-serif",
          background_color: "#efefef",
          border_style: "none",
          height: "30px",
          font_size: "16px",
          input_margin: "0.7em",
          input_border_radius: "3px",
          label_color: "#1f2021",
        },
      },
    }).then((instance) => {
      PTPayment.getControl("creditCard").label.text("Credit Card Number");
      PTPayment.getControl("expiration").label.text("Exp Date");
      instance !== null && setPaytraceInstance(instance);
      setUpdateInstance(false);
    });
  }, [clientKey, PTPayment, updateInstance]);

  const trimObjectFields = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].replace(/\s+$/, "");
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        obj[key] = trimObjectFields(obj[key]);
      }
    }
    return obj;
  };

  const onSubmit = useCallback(
    async (data) => {
      if (claimNumber) {
        setPaytraceError([]);
        if (!paytraceInstance && !PTPayment) {
          return;
        }
        const payload = trimObjectFields({
          amount: Number(amount),
          integrator_id: integrator_id,
          billing_address: {
            name:
              data.billing_address_first_name +
              " " +
              data.billing_address_last_name,
            street_address: data.billing_address_display,
            city: data.billing_address_city,
            state: data.billing_address_state,
            zip: data.billing_address_zip,
          },
        });

        for (const key in payload.billing_address) {
          if (payload.billing_address[key] === "") {
            setFormSubmittedWarning(
              "Please fill out all required fields before submitting.",
            );
            return;
          } else {
            setFormSubmittedWarning("");
          }
        }
        setIsLoading(true);
        PTPayment.validate(function (validationErrors) {
          PTPayment.style({ cc: { border_style: "none" } });
          PTPayment.style({ exp: { border_style: "none" } });
          if (validationErrors.length >= 1) {
            setPaytraceError(validationErrors);
            validationErrors.forEach((error) => {
              switch (error.responseCode) {
                case "35":
                  PTPayment.style({
                    cc: { border_color: "red", border_style: "solid" },
                  });
                  break;
                case "400":
                  if (error.description === "credit card type not allowed") {
                    PTPayment.style({
                      cc: { border_color: "red", border_style: "solid" },
                    });
                  } else if (
                    error.description === "card number is a required field"
                  ) {
                    PTPayment.style({
                      cc: { border_color: "red", border_style: "solid" },
                    });
                  } else if (
                    error.description === "exp year is a required field"
                  ) {
                    PTPayment.style({
                      exp: { border_color: "red", border_style: "solid" },
                    });
                  } else if (
                    error.description === "expiration month required"
                  ) {
                    PTPayment.style({
                      exp: { border_color: "red", border_style: "solid" },
                    });
                  }
                  break;
                case "44":
                  PTPayment.style({
                    exp: { border_color: "red", border_style: "solid" },
                  });
                  break;
                case "43":
                  PTPayment.style({
                    exp: { border_color: "red", border_style: "solid" },
                  });
                  break;
                default:
                  break;
              }
            });
            setIsLoading(false);
          } else {
            PTPayment.style({ cc: { border_style: "none" } });
            PTPayment.style({ exp: { border_style: "none" } });
            paytraceInstance
              .process()
              .then((validation) => {
                payload.hpf_token = validation.message.hpf_token;
                payload.enc_key = validation.message.enc_key;
                grecaptcha.enterprise.ready(() => {
                  grecaptcha.enterprise
                    .execute({ action: "submit" })
                    .then(async function () {
                      await saleTransaction(
                        oAuthToken,
                        trimObjectFields(payload),
                        claimNumber,
                      )
                        .then((response) => {
                          setIsLoading(false);
                          setFormSubmitted(true);
                          setFormSubmittedPolicy(true);
                          setFormSubmittedMessage(
                            response?.data?.message.status_message,
                          );
                          setFormSubmittedWarning(
                            response?.data?.message.status_message,
                          );
                        })
                        .catch((error = AxiosError) => {
                          const message =
                            error?.response?.data?.message?.errors ??
                            error?.response?.data?.message?.approval_message;
                          setIsLoading(false);
                          setUpdateInstance(true);
                          setFormSubmitted(false);
                          setFormSubmittedMessage(
                            Object.values(message).flat(),
                          );
                          setFormSubmittedWarning(
                            Object.values(message).flat(),
                          );
                        });
                    });
                });
              })
              .catch((err) =>
                setFormSubmittedWarning(
                  "An error has occurred, please contact Unique Insurance +1-866-426-8842",
                ),
              );
          }
        });
      } else {
        paytrace.setKey(policyKey);
        var payload = {
          delete_cc_on_success: true,
          card_number: paytrace.encryptValue(data.card_number),
          expiration_date: `${data.billingYear}-${data.billingMonth}`,
          security_code: paytrace.encryptValue(data.security_code),
          billing_address_city: data.billing_address_city,
          billing_address_country: data.billing_address_country,
          billing_address_display: data.billing_address_display,
          billing_address_first_name: data.billing_address_first_name,
          billing_address_last_name: data.billing_address_last_name,
          billing_address_state: data.billing_address_state,
          billing_address_zip: data.billing_address_zip,
        };
        grecaptcha.enterprise.ready(() => {
          grecaptcha.enterprise
            .execute({ action: "submit" })
            .then(async function (token) {
              setIsLoading(true);
              await makeAPayment(policyNumber, payload)
                .then((response) => {
                  setIsLoading(false);
                  setFormSubmitted(true);
                  setFormSubmittedPolicy(true);
                  setFormSubmittedMessage(response?.data?.message);
                  setFormSubmittedWarning(response?.data?.warnings);
                })
                .catch((error = AxiosError) => {
                  setIsLoading(false);
                  setFormSubmitted(false);
                  setFormSubmittedMessage(error?.response?.data?.message);
                  setFormSubmittedWarning(
                    "An error has occurred, please contact Unique Insurance +1-866-426-8842",
                  );
                });
            });
        });
      }
    },
    [paytraceInstance, grecaptcha],
  );

  return (
    <>
      <form className={className} onSubmit={handleSubmit(onSubmit)}>
        <Fieldset legend="Payment Information">
          {formSubmittedMessage && (
            <Row
              className={
                formSubmitted
                  ? "PolicyForm__success__message"
                  : "PolicyForm__error__message"
              }
            >
              <h3>{formSubmittedMessage}</h3>
            </Row>
          )}
          <Row hidden={formSubmitted} className="CreditCard__Form">
            <Col xs={12} sm={6} md={6}>
              <Input
                autoComplete="off"
                error={form?.formState.errors.billing_address_first_name}
                id="billing_address_first_name"
                label="First name"
                touched={
                  form?.formState.touchedFields.billing_address_first_name
                }
                {...form.register("billing_address_first_name", {
                  onBlur: () => form?.trigger("billing_address_first_name"),
                  required: "This field is required.",
                })}
              />
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Input
                autoComplete="off"
                error={form?.formState.errors.billing_address_last_name}
                id="billing_address_last_name"
                label="Last name"
                touched={
                  form?.formState.touchedFields.billing_address_last_name
                }
                {...form?.register("billing_address_last_name", {
                  onBlur: () => form?.trigger("billing_address_last_name"),
                  required: "This field is required.",
                })}
              />
            </Col>
            {policyNumber && (
              <>
                <Col xs={12} sm={12}>
                  <Input
                    autoComplete="off"
                    error={form?.formState.errors.card_number}
                    id="card_number"
                    label="Credit Card Number"
                    className="pt-encrypt"
                    type="tel"
                    inputMode="numeric"
                    maxLength={19}
                    touched={form?.formState.touchedFields.card_number}
                    {...form?.register("card_number", {
                      onBlur: () => form?.trigger("card_number"),
                      required: "This field is required.",
                    })}
                  />
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <Select
                    autoComplete="off"
                    id="billingMonth"
                    label="Month"
                    {...form?.register("billingMonth", {
                      onBlur: () => form?.trigger("billingMonth"),
                      required: "This field is required.",
                    })}
                  >
                    <option disabled value="" />
                    {range(1, 13).map((i) => (
                      <option key={i} value={("0" + i).slice(-2)}>
                        {("0" + i).slice(-2)}
                      </option>
                    ))}
                  </Select>
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <Select
                    autoComplete="off"
                    id="billingYear"
                    label="Year"
                    {...form?.register("billingYear", {
                      onBlur: () => form?.trigger("billingYear"),
                      required: "This field is required.",
                    })}
                  >
                    <option disabled value="" />
                    {range(new Date().getFullYear(), currentYear, +1).map(
                      (i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ),
                    )}
                  </Select>
                </Col>
                <Col xs={12} sm={6} md={6}>
                  <Input
                    autoComplete="off"
                    error={form?.formState.errors.security_code}
                    id="security_code"
                    label="Security Code"
                    className="pt-encrypt"
                    type="tel"
                    inputMode="numeric"
                    maxLength={4}
                    touched={form?.formState.touchedFields.security_code}
                    {...form?.register("security_code", {
                      onBlur: () => form?.trigger("security_code"),
                      required: "This field is required.",
                    })}
                  />
                </Col>
              </>
            )}

            <Col xs={12} sm={6} md={6}>
              <Input
                autoComplete="off"
                error={form?.formState.errors.billing_address_display}
                id="billing_address_display"
                label="Street address"
                touched={form?.formState.touchedFields.billing_address_display}
                {...form?.register("billing_address_display", {
                  onBlur: () => form?.trigger("billing_address_display"),
                  required: "This field is required.",
                })}
              />
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Input
                autoComplete="off"
                error={form?.formState.errors.billing_address_city}
                id="billing_address_city"
                label="City"
                touched={form?.formState.touchedFields.billing_address_city}
                {...form?.register("billing_address_city", {
                  onBlur: () => form?.trigger("billing_address_city"),
                  required: "This field is required.",
                })}
              />
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Select
                autoComplete="off"
                id="billing_address_state"
                label="State"
                error={form?.formState.errors.billing_address_state}
                touched={form?.formState.touchedFields.billing_address_state}
                {...form?.register("billing_address_state", {
                  onBlur: () => form?.trigger("billing_address_state"),
                  required: "This field is required.",
                })}
              >
                <option disabled value="" />
                {usStates.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Select>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Input
                autoComplete="off"
                id="billing_address_zip"
                label="Zip Code"
                maxLength={10}
                error={form?.formState.errors.billing_address_zip}
                touched={form?.formState.touchedFields.billing_address_zip}
                {...form?.register("billing_address_zip", {
                  onBlur: () => form?.trigger("billing_address_zip"),
                  required: "This field is required.",
                })}
              />
            </Col>
            {claimNumber && (
              <>
                <div id="pt_hpf_form"></div>
                {paytraceError.map((error) => (
                  <b className="PolicyForm__error__label">
                    {error.description}
                  </b>
                ))}
              </>
            )}
            <Col xs={12} md={12}>
              <div className="PolicyForm__error__block">
                {formSubmittedWarning && <h2>{formSubmittedWarning}</h2>}
              </div>
            </Col>
            <Col xs={12}>
              <Button
                type="submit"
                style={{ width: "100%", marginTop: "1.5rem" }}
                className="g-recaptcha"
                data-sitekey={recaptchaApiKey}
                data-callback="onSubmit"
                data-action="submit"
                data-size="invisible"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </Col>
          </Row>
          {formSubmitted && (
            <Col xs={12} md={12}>
              <div className="PolicyForm__success__block">
                {formSubmittedWarning && <h2>{formSubmittedWarning}</h2>}
              </div>
            </Col>
          )}
        </Fieldset>
      </form>
      {isLoading && (
        <Modal
          isCloseButtonVisible={false}
          onClose={() => {}}
          open={true}
          width="max-content"
          panelClassName="loading__modal"
        >
          <Spinner animation="grow"> </Spinner>
          <p>Processing your payment</p>
        </Modal>
      )}
    </>
  );
};

export default CreditCardForm;
